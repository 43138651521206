import { h } from "preact";

import { memo } from "preact/compat";
import Link from "next/link";

import { LayoutWrapper } from "@/components/LayoutWrapper";
import { PageHead } from "@/components/PageHead";

const HeadingSection = memo(() => (
  <div class="relative px-6 py-3 sm:pb-1 container mx-auto">
    <h1 class="sm:text-lg text-xl font-bold text-gray-700">
      A volunteer-led effort to help you find a COVID-19 vaccine in and around
      NYC
    </h1>
  </div>
));

const NotesSection = memo(() => {
  return (
    <section>
      <div class="divide-y divide-gray-200">
        <div class="text-base leading-6">
          <p class="text-xl text-gray-800 max-w-full lg:max-w-prose pt-4 pb-4">
            November 2021 update: Thank you!
          </p>

          <p class="text-lg text-gray-600 max-w-full lg:max-w-prose">
            NYC Vaccine List was created to help folks find vaccines. Now that
            there is plenty of vaccine available, we're no longer needed.
          </p>
          <p class="text-lg text-gray-600 max-w-full lg:max-w-prose py-4">
            Huge thanks to everyone who worked so hard to help our community! ❤️
          </p>
          <p class="text-lg text-gray-600 max-w-full lg:max-w-prose">
            If you're looking for a vaccine, please visit{" "}
            <a
              href="https://vax4nyc.nyc.gov/"
              className="underline text-linkBlue hover:text-blue-800 visited:text-purple-600"
            >
              Vax4NYC
            </a>
            ,{" "}
            <a
              href="https://www.vaccines.gov/"
              className="underline text-linkBlue hover:text-blue-800 visited:text-purple-600"
            >
              Vaccines.gov
            </a>
            , or your local pharmacy.
          </p>
          <p class="text-lg text-gray-600 max-w-full lg:max-w-prose pt-4">
            - Dan, Cameron, Michael, and all the
            <Link href="/about#team">
              <a className="text-cyan-700 hover:text-cyan-500">
                {" "}
                NYC Vaccine List volunteers
              </a>
            </Link>{" "}
          </p>
        </div>
      </div>
    </section>
  );
});

export const PageHome = () => {
  const cleanByArea = "New York";
  const pageTitle = `${
    cleanByArea ? `${cleanByArea} - ` : ""
  }Find NYC Covid-19 vaccines`;

  return (
    <LayoutWrapper>
      <PageHead title={pageTitle} />
      <HeadingSection />
      <section class="relative py-3 container mx-auto">
        <div class="relative px-6 rounded">
          <NotesSection />
        </div>
      </section>
      {/* <BodySection locations={locations} regions={regions} /> */}
    </LayoutWrapper>
  );
};

export default PageHome;
